import React, { Component } from "react";
import './css/styles.css';

// libs
import CircularProgress from "@material-ui/core/CircularProgress";

const landingList = {
    lizanice: "https://payphone.io/lizanice"
}

class RedirectUserProfile extends Component {

	componentDidMount() {
        const webDomain = process.env.REACT_APP_WEB_APP_DOMAIN;
		const query = new URLSearchParams(this.props.location.search);
		const urlRef = query.get("ref");
		const token = this.props.location.pathname.split('/')[this.props.location.pathname.split('/').length - 1];
		
        // android link
        const androidToken = urlRef ? token + "?ref=" + urlRef : token;
		const timestamp = +new Date(); // for detecting uniqe enterence in android deeplinking
		const androidURL = "payphone://user-profile/" + androidToken + "-" + timestamp;
        
        // web link
        let webURL = new URL(webDomain + "/profile/" + token);
		if (urlRef) webURL.searchParams.append('ref', urlRef);
        if (this.props.match.url.includes('/ts/')) webURL.searchParams.append('t', 's');
        if (this.props.match.url.includes('/l/')) webURL = landingList[this.props.match.params.nickname];

		if (/Android/i.test(navigator.userAgent)) {
			window.location = androidURL;

			window.setTimeout(() => {
				window.location = webURL;
			}, 25);
		} else {
			window.location = webURL;
		}

		function killPopup() {
			window.removeEventListener("pagehide", killPopup);
		}
		window.addEventListener("pagehide", killPopup);
	}

	render() {
		return (
			<div className="circular-progress-container">
				<CircularProgress size={50} />
			</div>
		);
	}
}

export default RedirectUserProfile;
