import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import RedirectUserProfile from 'components/redirects/profile/RedirectUserProfile';
import TargetStore from 'components/redirects/profile/TargetStore';

const Root = (props) => (
	<Router {...props}>
		<div className="app-root">
			<Switch>
                <Route path="/ts/:nickname" component={TargetStore} />
                <Route path="/l/:nickname" component={RedirectUserProfile} />
				<Route path="/:nickname" component={RedirectUserProfile} />
			</Switch>
		</div>
	</Router>
);

export default Root;