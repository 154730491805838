import React, { Component } from "react";
import './css/styles.css';

// imgs
import APP_STORE_LOGO from "imgs/App_store.svg";
import PLAY_MARKET_LOGO from "imgs/google_play.svg";

const androidURL = "https://play.google.com/store/apps/details?id=com.payphone.android&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1";
const iOSURL = "https://itunes.apple.com/ua/app/payphone-x/id1391754946";

const texts = {
	en: {
		title: "Download PayPhone X",
		text: "For continuing download the application using one of the links below:"
	},
	ru: {
		title: "Cкачайте PayPhone X",
		text: "Для продолжения — скачайте приложение по одной из ссылок ниже:"
	},
	uk: {
		title: "Завантажте PayPhone X",
		text: "Для продовження — завантажте додаток за одним із посилань нижче:"
	}
};

class RedirectUserProfile extends Component {

    constructor(props) {
		super(props);
        this.copyToClipboard = this.copyToClipboard.bind(this);
        
		this.state = {
			lang: "en"
		};
        localStorage.removeItem('loginParams');
	}

	componentDidMount() {
		// if (/Android/i.test(navigator.userAgent)) {
		// 	window.location = androidURL;
		// } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
		// 	window.location = iOSURL;
		// }

		const userLang = this.getFirstBrowserLanguage();

		switch (true) {
			case userLang.indexOf("en") !== -1:
				this.setState({ lang: "en" });
				break;
			case userLang.indexOf("ru") !== -1:
				this.setState({ lang: "ru" });
				break;
			case userLang.indexOf("uk") !== -1:
				this.setState({ lang: "uk" });
				break;
			default:
				this.setState({ lang: "en" });
				break;
		}
	}

    redirectToStore(target) {
        if (target === 'android') {
			window.location = androidURL;
		} else if (target === 'ios') {
			window.location = iOSURL;
		}
    }

	getFirstBrowserLanguage() {
		var nav = window.navigator,
			browserLanguagePropertyKeys = ["language", "browserLanguage", "systemLanguage", "userLanguage"],
			i,
			language;

		// support for HTML 5.1 "navigator.languages"
		if (Array.isArray(nav.languages)) {
			for (i = 0; i < nav.languages.length; i++) {
				language = nav.languages[i];
				if (language && language.length) {
					return language;
				}
			}
		}

		// support for other well known properties in browsers
		for (i = 0; i < browserLanguagePropertyKeys.length; i++) {
			language = nav[browserLanguagePropertyKeys[i]];
			if (language && language.length) {
				return language;
			}
		}

		return null;
	}

	// componentDidMount() {
    //     const webDomain = process.env.REACT_APP_WEB_APP_DOMAIN;
	// 	const query = new URLSearchParams(this.props.location.search);
	// 	const urlRef = query.get("ref");
	// 	const token = this.props.location.pathname.split('/')[this.props.location.pathname.split('/').length - 1];
		
    //     // android link
    //     const androidToken = urlRef ? token + "?ref=" + urlRef : token;
	// 	const timestamp = +new Date(); // for detecting uniqe enterence in android deeplinking
	// 	const androidURL = "payphone://user-profile/" + androidToken + "-" + timestamp;
        
    //     // web link
    //     let webURL = new URL(webDomain + "/profile/" + token);
	// 	if (urlRef) webURL.searchParams.append('ref', urlRef);
    //     if (this.props.match.url.includes('/ts/')) {
    //         //webURL.searchParams.append('t', 's');
    //         document.execCommand('copy');
    //     }
    //     if (this.props.match.url.includes('/l/')) webURL = landingList[this.props.match.params.nickname];

	// 	// if (/Android/i.test(navigator.userAgent)) {
	// 	// 	window.location = androidURL;

	// 	// 	window.setTimeout(() => {
	// 	// 		window.location = webURL;
	// 	// 	}, 25);
	// 	// } else {
	// 	// 	window.location = webURL;
	// 	// }

	// 	function killPopup() {
	// 		window.removeEventListener("pagehide", killPopup);
	// 	}
	// 	window.addEventListener("pagehide", killPopup);
	// }

    copyToClipboard = (elm, target) => {
        let el = this.textArea;
        if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
            // var el = $input.get(0);
            // var editable = el.contentEditable;
            // var readOnly = el.readOnly;
            // el.contentEditable = 'true';
            // el.readOnly = 'false';
            // var range = document.createRange();
            // range.selectNodeContents(el);
            // var sel = window.getSelection();
            // sel.removeAllRanges();
            // sel.addRange(range);
            // el.setSelectionRange(0, 999999);
            // el.contentEditable = editable;
            // el.readOnly = readOnly;
            var oldContentEditable = el.contentEditable,
            oldReadOnly = el.readOnly,
            range = document.createRange();

            el.contentEditable = true;
            el.readOnly = false;
            range.selectNodeContents(el);

            var s = window.getSelection();
            s.removeAllRanges();
            s.addRange(range);

            el.setSelectionRange(0, 999999); // A big number, to cover anything that could be inside the element.

            el.contentEditable = oldContentEditable;
            el.readOnly = oldReadOnly;
        } else {
            this.textArea.select();
        }
        document.execCommand('copy');
        alert('"' + this.textArea.value + '" value copied to clipboard!');
        // this.textArea.select();
        // document.execCommand('copy');

        this.redirectToStore(target);
    };

	render() {
		return (
			<div className="user-profile-redirect">
				<div className="blank center">
					<span className="title-5">{texts[this.state.lang].title}</span>
					<br />
					<span className="text-1">{texts[this.state.lang].text}</span>
					<div className="links-container">
						<button className="link" onClick={() => this.copyToClipboard(this, 'ios')}>
							<img className="link-img" alt="Get it on App Store" src={APP_STORE_LOGO} />
						</button>
						<button className="link" onClick={() => this.copyToClipboard(this, 'android')}>
							<img className="link-img" alt="Get it on Google Play" src={PLAY_MARKET_LOGO} />
						</button>
					</div>
                    <form className="form">
                        <textarea
                            ref={(textarea) => this.textArea = textarea}
                            value={window.location.href}
                            onChange={() => {}}
                        />
                    </form>
				</div>
			</div>
		);
	}
}

export default RedirectUserProfile;
